const user_scopes = {
    descriptions: {
        custom_data: 'I tuoi dati personalizzati',
        email: 'Il tuo indirizzo email',
        phone: 'Il tuo numero di telefono',
        profile: 'Il tuo nome, nome utente, avatar e altre informazioni del profilo',
        roles: 'I tuoi ruoli',
        identities: 'Le tue identità social collegate',
        'urn:logto:scope:organizations': 'Le informazioni sulle tue organizzazioni',
        'urn:logto:scope:organization_roles': 'I tuoi ruoli organizzativi',
        address: 'Il tuo indirizzo',
    },
};
export default Object.freeze(user_scopes);
